import React from "react";
import { Link } from "gatsby";
import GatsbyImages from "../../GatsbyImages";
import "./MoreAboutUs.css";

const MoreAboutUs = ({ data }) => {
  return (
    <div className="moreAboutUs">
      <div className="container">
        <h2>{data.Title}</h2>
        <div
          className="description"
          dangerouslySetInnerHTML={{
            __html: data.Text,
          }}
        ></div>

        <ul className="clearfix  flex-wrap">
          {data.IconTextLink.map((val, index) => {
            if (val.Link === "/contacto") {
              return (
                <li key={index}>
                  <Link to={val.Link} className="mapLink">
                    <GatsbyImages
                      src={val.icon.localFile.childImageSharp.gatsbyImageData}
                      ext={val.icon.localFile.ext}
                      alt={val.icon.alternativeText}
                      width={28}
                      height={28}
                    />
                    <span>{val.Title}</span>
                  </Link>
                </li>
              );
            }
            return (
              <li key={index}>
                <a href={val.Link} className="mapLink">
                  <GatsbyImages
                    src={val.icon.localFile.childImageSharp.gatsbyImageData}
                    ext={val.icon.localFile.ext}
                    alt={val.icon.alternativeText}
                    width={24}
                    height={24}
                  />
                  <span>{val.Title}</span>
                </a>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};

export { MoreAboutUs };
