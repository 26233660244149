import React from "react";
import LiteYouTubeEmbed from "react-lite-youtube-embed";
import GatsbyImages from "../../GatsbyImages";
import "react-lite-youtube-embed/dist/LiteYouTubeEmbed.css";

const SubHeader = ({ data }) => {
  const isVideo = data.LinkVideo !== null && data.LinkVideo !== "";

  let videoId;
  // let videoHeight;
  // let videoWidth;
  if (isVideo) {
    const videoLink = data.LinkVideo;
    // Extracting width and height using regular expressions
    // const widthRegex = /width="(\d+)"/;
    // const heightRegex = /height="(\d+)"/;
    // const widthMatch = videoLink.match(widthRegex);
    // const heightMatch = videoLink.match(heightRegex);
    // videoWidth = widthMatch ? widthMatch[1] : "";
    // videoHeight = heightMatch ? heightMatch[1] : "";

    // Extracting video ID using regular expressions
    const videoIdRegex = /\/embed\/([a-zA-Z0-9_-]{11})/;
    const videoIdMatch = videoLink.match(videoIdRegex);
    videoId = videoIdMatch ? videoIdMatch[1] : "";
  }

  return (
    <div className="subheader mobile mt-4">
      <div className="container">
        <div className="mobile-in mobile-video d-flex flex-wrap">
          <div className="mobile-rgt col-lg-6 col-md-12">
            <h2
              dangerouslySetInnerHTML={{
                __html: data.Title,
              }}
            ></h2>
            <div
              dangerouslySetInnerHTML={{
                __html: data.Text,
              }}
            ></div>
          </div>
          <div className="mobile-lft col-lg-6 col-md-12">
            <>
              {(data.LinkVideo === "" || data.LinkVideo === null) && (
                <GatsbyImages
                  src={data.Image.localFile.childImageSharp.gatsbyImageData}
                  ext={data.Image.localFile.ext}
                  alt={data.Image.alternativeText}
                />
              )}
              {data.LinkVideo !== null && data.LinkVideo !== "" && (
                <span>
                  <LiteYouTubeEmbed
                    id={videoId}
                    title="video_player"
                    noCookie={true}
                    webp={true}
                  />
                </span>
              )}
            </>
          </div>
        </div>
      </div>
    </div>
  );
};

export { SubHeader };
