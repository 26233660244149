import React from "react";

import { Seo } from "../Layout/Seo";
import { Header } from "./Header";
import { SubHeader } from "./SubHeader";
import { Different } from "./Different";
import { MoreAboutUs } from "./MoreAboutUs/indexj";
import { Team } from "./Team";
import { TalkAboutUs } from "./TalkAboutUs";

const AboutUs = ({ pageContext, lang }) => {
  const { fragments } = pageContext;

  return (
    <Seo
      title={fragments.SeoTitle}
      description={fragments.SeoDescription}
      lang={lang}
    >
      <Header data={fragments.Title} />
      <SubHeader data={fragments.Header} />
      <Different data={fragments.Different} />
      <MoreAboutUs data={fragments.MoreAboutUs} />
      <Team data={fragments.Team} />
      <TalkAboutUs data={fragments.talkAboutUs} />
    </Seo>
  );
};

export default AboutUs;
