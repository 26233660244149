import React from "react";

const Different = ({ data }) => {
  return (
    <div className="diferent">
      <div className="container">
        <h2>{data.Title}</h2>

        <ul className="clearfix  flex-wrap">
          {data.ListTitleText.map((val, index) => {
            return (
              <li key={index}>
                <h4>{val.title}</h4>
                <div
                  dangerouslySetInnerHTML={{
                    __html: val.text === "" ? val.textFormat : val.text,
                  }}
                />
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};

export { Different };
