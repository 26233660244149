import React from "react";

const Team = ({ data }) => {
  return (
    <div className="team">
      <div className="container">
        <h2>{data.Title}</h2>
        <div
          className="description"
          dangerouslySetInnerHTML={{
            __html: data.Text,
          }}
        ></div>

        <ul className="clearfix  flex-wrap">
          {data.teamDetails.map((val, index) => {
            return (
              <li key={index}>
                <div>
                  <span>{val.Name}</span>
                  <span>{val.position}</span>
                </div>
                {/* this is an external link */}
                <a href={val.linkLikedin} className="mapLink">
                  {val.name}
                  <span className="sr-only">linkedin {val.Name}</span>
                </a>
              </li>
            );
          })}
        </ul>
        <div
          className="description"
          dangerouslySetInnerHTML={{
            __html: data.subtext,
          }}
        />
        <a className="button" href={`${data.buttonLink}`}>
          {data.buttonText}
        </a>
      </div>
    </div>
  );
};

export { Team };
