import React from "react";

const Header = ({ data }) => (
  <div className="banner faqs aboutus">
    <div className="container">
      <div className="content d-flex flex-wrap">
        <div className="content-lft col-xl-12 col-md-10 col-sm-12">
          <h1
            dangerouslySetInnerHTML={{
              __html: data,
            }}
          ></h1>
        </div>
      </div>
    </div>
  </div>
);

export { Header };
