import React from "react";
import GatsbyImages from "../../GatsbyImages";
const TalkAboutUs = ({ data }) => {
  return (
    <div className="talkAboutUs">
      <div className="container">
        <h2>{data.title}</h2>

        <ul className="clearfix  flex-wrap logos">
          {data.press.map((val, index) => {
            return (
              <li key={index}>
                <a
                  href={val.Link}
                  className="mapLink"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <GatsbyImages
                    src={val.Logo.localFile.childImageSharp.gatsbyImageData}
                    ext={val.Logo.localFile.ext}
                    alt={val.Logo.alternativeText}
                    width={185}
                    height={30}
                  />
                </a>
              </li>
            );
          })}
        </ul>

        <div className="logosm">
          {data.press.map((val, index) => {
            return (
              <div key={index}>
                <a
                  href={val.Link}
                  // className="mapLink"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <GatsbyImages
                    src={val.Logo.localFile.childImageSharp.gatsbyImageData}
                    ext={val.Logo.localFile.ext}
                    alt={val.Logo.alternativeText}
                    width={185}
                    height={63}
                  />
                </a>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export { TalkAboutUs };
